import React from "react";
import Faqs from "../../components/global/Faqs";

const FAQPreview = ({ entry }) => {
  const data = entry?.getIn(["data"])?.toJS();
  if (data) {
    return (
      <>
        <Faqs title="FAQ example" faqs={[{ ...data }]} />
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default FAQPreview;
