exports.buildPathPrefix = locale => {
  switch (locale?.toLocaleLowerCase()) {
    case "au-en":
      return "/au";
    case "nl-nl":
      return "/nl";
    case "ch-de":
      return "/ch/de";
    case "ch-en":
      return "/ch/en";
    case "ch-fr":
      return "/ch/fr";
    case "ch-it":
      return "/ch/it";
    case "uk-en":
      return "/uk";
    default:
      return "/";
  }
};
