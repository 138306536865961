import React, {useState, useEffect} from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import {buildPathPrefix} from "../../utils/path-utils"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

const Faqs = props => {
  const { title, questions, locale, toFaqButtonText } = props

  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    if (window.innerWidth < 768) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  const linkPrefix = buildPathPrefix(locale);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  })
  
  return (
    <section className="faq-area bg-light">
      <div className="container">
        <div className="row main-banner-content">
          <div className="col-lg-12 col-md-12 ">
            <ReactWOW delay=".1s" animation="fadeInLeft">
              <h2 className="footer-title">{title}</h2>
            </ReactWOW>
          </div>
        </div>
        <div className="row mx-3 mx-md-0">
          {questions?.filter(q => !q.draft).slice(0, isMobile ? 3 : 6).map((question, index) => (
            <div className="col-12 col-md-6" key={index}>
              <div className="faq-accordion accordion__spacing">
                <Accordion allowZeroExpanded allowMultipleExpanded>
                  <AccordionItem uuid={index}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{question?.question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>{question?.answer}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          ))}

          <div className="text-center pt-1 pb-4 mx-auto">
              <div className="btn-box pt-5">
                <Link to={`${linkPrefix}/faq/`} className="default-btn-black">
                  {toFaqButtonText} <span></span>
                </Link>
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faqs
